.productCard {
    margin: 0 0 5px 0;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(227, 227, 227, 1);

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: fit-content;
}

.productCard.verticalMode {
    flex-direction: column;
    align-items: center;
    height: inherit;
}

.imageWrapper {
    max-width: 40%;
}

.imageWrapper.verticalMode {
    max-width: 100%;
    height: 50%;
}

.hide-description {
    display: none;
}
