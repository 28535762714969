.optionsWrapper {
    font-family: "Lato", Arial, Helvetica, sans-serif;
}

.options {
    padding: 1rem;
    max-width: 80%;

    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1000px){
    .options {
        max-width: 100%;
    }
}

.accordion {
    padding-bottom: 1rem;
}

.accordionHeader {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 1.125rem;
    color: rgb(102 102 102);
}

.question {
    align-self: center;

    font-size: 1.25rem;
    font-weight: 700;
    font-style: italic;
}
