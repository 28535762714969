.autocomplete-suggestion {
    padding: 15px;
    display: flex;
    width: 25%;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 11%);
}

@media screen and (max-width: 1000px) {
    .autocomplete-suggestion {
        width: auto;
    }
}

.autocomplete-suggestion:hover {
    background-color: #e5e9ec;
}
