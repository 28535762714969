.tinkoffButton {

}

.wrapper {
    display: flex;
    padding-top: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

@media screen and (max-width: 1000px){
    .wrapper {
        align-items: stretch;
    }

    .productButtons {
        justify-content: center;
    }
}

.productButtons {
    display: flex;
    flex-direction: row;
}

.instantPurchase {
    height: 2rem;
}
