.ultranout_accordion_header {

}

.ultranout_accordion_header-text {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: rgb(102 102 102);
}

.static-page_text {
    width: 80%;
    padding-left: 1rem;
    padding-bottom: 2.25rem;
    font-family: "Lato", Arial, Helvetica, sans-serif;
    font-size: 14px;
    white-space: pre-wrap;
    /*color: rgb(119 119 119);*/
}

.static-page_text-plain {
    margin: 20px;
}

.static-page_text-list-item {
    margin: 10px;
    font-size: 14px;
}

.link {
    text-decoration: none;
    color: rgb(51 51 51);
}

.button-wrapper {
    color: gray;
    border: none;
    background-color: white;
    padding-right: 0;
}

.button-wrapper_clicked {
    color: black;
    font-weight: bold;
    border: none;
    background-color: white;
    padding-right: 0;
}

.modal-footer {
    display: flex;
    justify-content: center !important;
}
