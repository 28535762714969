.carouselContainer {
    position: relative;
    width: 100%;
    padding: 1rem;
}

.imageWrapper {
    position: relative;
}

.mainImage {
    cursor: pointer;
    border-radius: 0.5rem;
    width: 100%;
}

.arrowButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    background: rgba(128, 128, 128, 0.1);
    border: none;
    font-size: 2.5rem;
    color: white;
    cursor: pointer;
    padding: 1rem;
    height: 20%;
    transition: background 0.3s ease;
}

.arrowButton:hover {
    background: rgba(128, 128, 128, 0.3);
}

.leftArrow {
    left: 0.625rem;
}

.rightArrow {
    right: 0.625rem;
}

.thumbnailsContainer {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    gap: 0.625rem;
    overflow-x: auto;
    padding: 0.675rem;
}

@media (max-width: 768px) {
    .thumbnailsContainer {
        justify-content: flex-start;
        padding: 0.675rem;
    }
}

.thumbnail {
    width: 3.75rem;
    height: 3.75rem;
    object-fit: cover;
    border-radius: 0.25rem;
    cursor: pointer;
    border: 0.125rem solid transparent;
    transition: transform 0.2s, border-color 0.2s;
}

.activeThumbnail {
    transform: scale(1.1);
    border-color: var(--var-main-color);
}

.fullscreenContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.fullscreenImage {
    max-width: 80%;
    max-height: 80%;
    width: auto;
    height: auto;
}

.fullscreenArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
    background: none;
    border: none;
    font-size: 4rem;
    color: white;
    cursor: pointer;
    height: 100%;
}

.fullscreenArrow.leftArrow {
    left: 1rem;
}

.fullscreenArrow.rightArrow {
    right: 1rem;
}
