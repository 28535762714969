.footer {
    padding: 20px 0px 20px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    border-color: rgba(227, 227, 227, 1);
    border-top-width: 1px;
    border-top-style: solid;
}
