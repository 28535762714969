.no-match {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.no-match_header {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

.top {
    font-size: 45px;
    font-weight: 900;
}

.bottom {
    font-size: 28px;
    font-weight: 600;
}

.no-match_main-content {
    position: relative;
    margin: 70px 0 70px 0;
    font-size: 45px !important;
    font-weight: 900 !important;
    color: rgba(245, 102, 34, 1);
}

.no-match_main-content:before {
    content: "404";
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    color: rgb(241, 241, 241);
    font-weight: 700;
    font-size: 5em;
    line-height: 1;
    transform: translate(-50%, -50%);
}

.no-match_additional {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}