.accordion {
    flex: 1 0 21%;
}

.slider {
    margin: 20px 0 20px 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 2px;
}

.thumb {
    width: 26px;
    height: 26px;
    cursor: grab;
    background: #ffffff;
    border-radius: 50%;
    border: 2px solid rgb(245 102 34);
}
