.about-page_title {
    padding: 60px;
    text-align: center;

    background-color: black;
    background-image: url("http://cristina.ultranout.ru/wp-content/uploads/2022/02/pagetitle-10.jpg");
    background-size: cover;
    background-position: center;
}

.about-page_title-text {
    color: white;
    font-weight: 600;
    font-size: 68px;
    line-height: 1.2;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}
