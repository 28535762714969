.page_title {
    padding: 60px;
    text-align: center;
    margin-bottom: 40px;

    background-color: black;
    background-image: url("https://ultranout.ru/old/wp-content/uploads/2022/02/pagetitle-10.jpg");
    background-size: cover;
    background-position: center;
}

.page_title-title {
    /*padding: 60px;*/
    color: white;
    font-weight: 600;
    font-size: 68px;
    line-height: 1.2;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 1000px) {
    .page_title-title {
        font-size: 2.375rem;
    }
}

.page_title-bottomText {
    text-decoration: none;
    color: white;
    font-weight: 600;
    font-size: 11px;
    line-height: 1.2;
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    text-transform: uppercase;
}
