.logoutButton {
    margin: 0.5rem;
}

.mobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem;
    border-bottom: 1px solid grey;
}

.navbar {
    width: 80% !important;
}

.offcanvas {
    padding: 0 !important;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding-right: 0.375rem;
    gap: 0.5rem
}

.icon {
    width: 2rem;
    height: 2rem;

    color: rgb(62,62,62);

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    border-radius: 3rem;
}

.yandexMarket {
    border: none;
}

.yandexMarket::after {
    content: '';
    background-image: url("https://ultranout.ru/old/wp-content/uploads/2023/12/558.png");
    width: 2rem;
    background-size: contain;
    height: 2rem;
    display: inline-block !important;
    position: relative;
    top: 3px
}
