.mobileFilters {
    margin: 0.5rem 0 0.5rem 0;
    font-size: 1.3rem;
    font-weight: 500;
}

.confirmButton {
    width: 100%;
}

.confirmButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    background-color: rgba(0, 0, 0, 0.5);
    height: 8rem;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
}

/*.menuIcon {*/
/*    border: 1px solid;*/
/*    !*color: white;*!*/
/*    background-color: var(--var-main-color);*/
/*}*/
