.pagination {
    display: flex;
    justify-content: center;
    margin: 5px 0 5px 0;
}

.pagination_item {
    display: inline-block;
    min-width: 34px;
    height: 34px;
    text-align: center;
    padding: 0 5px 0 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 34px;
    /*border: 1px solid black;*/
}

.bg-ultranout-selected-filters {
    font-weight: normal !important;
    font-size: 12px !important;
    color: black !important;
    background-color: white;
    border: 1px solid rgba(245, 102, 34, 1);
    border-radius: 2.375rem !important;
    margin-right: 0.2rem;
    margin-bottom: 0.2rem;
}

.bg-ultranout-selected-filters:hover {
    cursor: pointer;
}
/*.pagination_item:hover {*/
/*    background-color: rgba(245, 102, 34, 1);*/
/*}*/

.pagination_active, .pagination_item:hover {
    color: white;
    background-color: rgba(245, 102, 34, 1);
}

.pagination_link {
    color: black;
    text-decoration: none;
}

.ultranout-catalogue-accordion {
    --bs-accordion-border-width: none !important;
    --bs-accordion-active-color: black !important;
    --bs-accordion-btn-padding-y: 0.7rem !important;
    background-color: transparent !important;
}

.mobile-filters {
    padding: 0.375rem !important;
}
