.filtersWrapper {
    display: flex;
    flex-direction: column;
}


.filterBody {
    max-height: 200px;
    overflow: auto;
}
