@media screen and (max-width: 1000px) {
    .wishlist-wrapper {
        text-align: center;
        margin-top: 0.375rem;
    }
}

.wishlist {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1000px) {
    .wishlist {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.wishlist_products {
    display: flex;
    gap: 2rem;
    flex-direction: row;
    flex-wrap: wrap;
    width: 70%;
    padding: 10px
}

@media screen and (max-width: 1000px) {
    .wishlist_products {
        width: 100%;
    }
}

.product-card_wrapper {
    width: 33.3%;
}

@media screen and (max-width: 1000px) {
    .product-card_wrapper {
        width: 100%;
    }
}