.button_main_color {
    border: none !important;
    color: white !important;
    background-color: rgba(245, 102, 34, 1) !important;
}

.button_main_color-rounded {
    color: white !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    background-color: rgba(245, 102, 34, 1) !important;
    border-radius: 35px !important;
}

.button_main_color:hover {
    color: white !important;
    background-color: rgba(200, 102, 34, 1) !important;
}

.button_secondary_color {
    color: rgba(245, 102, 34, 1) !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    background-color: white !important;
    border: 1px solid rgba(227, 227, 227, 1) !important;
}

.button_secondary_color:hover {
    color: rgba(245, 102, 34, 1) !important;
    background-color: lightgrey !important;
}
