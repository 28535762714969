.store-rules_accordion-header {
    /*color: rgb(102 102 102);*/
}

.store-rules_accordion-header-text {
    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-size: 18px;
    color: rgb(102 102 102);
}

.accordion-button:not(.collapsed) {
    background-color: rgba(225, 225, 225, 1) !important;
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem rgba(225, 225, 225, 1) !important;
}

/*TODO*/
.accordion-button:not(.collapsed)::after {
    color: rgba(225, 225, 225, 1) !important;
}
