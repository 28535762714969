.track {
    width: auto;
    height: 2px;
}

.track-0 {
    background-color: grey !important;
}

.track-1 {
    background-color: rgb(245 102 34) !important;
}

.track-2 {
    background-color: grey !important;
}
