.mainPage {
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.mobileTop {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1000px){
    .mobileTop {
        display: none;
    }
}

.mobileLink {
    align-self: center;
    width: 95%;
}

.mobileButton {
    margin-top: 0.375rem;
    width: 100%;
    border-radius: 30px !important;
    margin-bottom: 10px;
}

.laptopPictures {
    display: flex;
    flex-direction: column;
}

.topRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-bottom: 2rem;
    gap: 0.375rem;
}

@media screen and (max-width: 1000px) {
    .topRow {
        flex-direction: column;
        padding-bottom: 0.375rem;
    }
}

.bottomRow {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 0.375rem;
}

@media screen and (max-width: 1000px) {
    .bottomRow {
        flex-direction: column;
    }
}

.image {
    padding: 0 15px 0 15px;
    width: 100%;
    height: 100%;
}

.serviceIcons {
    padding: 0 2rem 0 2rem;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
}

@media screen and (max-width: 1000px) {
    .serviceIcons {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    }
}

.serviceIconsItem {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.bottomBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 20px;
}

@media screen and (max-width: 1000px) {
    .bottomBlock {
        flex-direction: column;
    }
}
