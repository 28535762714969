.header {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 300;
}

.header-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    border-color: rgba(227, 227, 227, 1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.header-search_icons {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.view-all-results {
    line-height: 50px;
    text-align: center;
    background-color: white;
    border: 1px solid rgb(0 0 0 / 11%);
    width: 100%;
    cursor: pointer;
    font-weight: 700;
}

.view-all-results:hover {
    background-color: #e5e9ec;
}

.bg-ultranout_color {
    font-size: 9px !important;
    position: relative;
    top: 13px;
    left: 16px;
    background-color: rgba(245, 102, 34, 1) !important;
}

.bg-ultranout_color_mobile {
    font-size: 9px !important;
    position: relative;
    top: -12px;
    left: 35px;
    background-color: rgba(245, 102, 34, 1) !important;
}

.header-social_links-icon:hover {
    color: rgba(245, 102, 34, 1);
}

.yandex_market::after {
    content: '';
    background-image: url("https://ultranout.ru/old/wp-content/uploads/2023/12/558.png");
    width: 25px;
    background-size: contain;
    height: 25px;
    display: inline-block !important;
}

.mega_market::after {
    content: '';
    background-image: url("https://ultranout.ru/old/wp-content/uploads/2023/12/megamarket_logotip_2023-1.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100px;
    background-size: contain;
    height: 25px;
    display: inline-block !important;
}

.whatsapp {
    color: rgb(37, 211, 102) !important;
    background-color: white !important;
}

.vkontakte:hover {
    color: rgb(0 119 255) !important;
    background-color: white !important;
}

.telegram:hover {
    color: rgb(42 171 238) !important;
    background-color: white !important;
}

.avito:hover {
    background-image: linear-gradient(90deg, rgba(29,209,36,1) 30%, rgba(29,152,209,1) 30% 60%, rgba(131,20,176,1) 60% 70%, rgba(255,0,0,1) 70% 100%) !important;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

/*.header-search_button {*/
/*    background-color: rgb(245,102,34) !important;*/
/*    border-color: rgb(245,102,34) !important;*/
/*    transition: box-shadow .3s !important;*/
/*}*/

/*.header-search_button :hover {*/
/*    box-shadow: 0 0 11px rgba(33,33,33,.2) !important;*/
/*}*/

.header-social {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;

    border-color: rgba(227, 227, 227, 1);
    border-bottom-width: 1px;
    border-bottom-style: solid;

    font-family: "Montserrat", Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 13px;
    color: rgb(62 62 62);
}

.header-social_links {
    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    padding-left: 10px;
}

.header-social_links-icon {
    text-decoration: none;
    color: rgb(51 51 51);
    padding: 0 5px 0 5px;
}

.header-social_links-text {
    text-decoration: none;
    color: rgb(51 51 51);
    padding: 0 5px 0 5px;
}

.header-social_contacts {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
}

.header-social_contacts-element {
    text-decoration: none !important;
    color: black;
    padding: 0px 10px 0px 10px;
    border-color: rgba(227, 227, 227, 1);
    border-right-width: 1px;
    border-right-style: solid;
}

.header-social_contacts-element-button {
    margin-left: 10px;
    /*padding: 12px 20px 12px 20px !important;*/
    /*color: rgb(62 62 62) !important;*/
    /*font-size: 13px !important;*/
    /*line-height: 18px !important;*/
    /*font-weight: 600 !important;*/
    /*text-transform: uppercase !important;*/
    /*border: 0 solid transparent !important;*/
    /*border-radius: 0 !important;*/
    /*background-color: rgb(243 243 243) !important;*/
    /*transition: 0.3s !important;*/
}

.header-social_contacts-element-button:hover {
    /*filter: brightness(0.90);*/
}

.header-search {
    display: flex;
    flex-direction: row;
    padding: 10px;
    vertical-align: center;
}

.header-navbar {
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1.25rem;
    padding: 10px;
    text-align: center;
}

.header-navbar_item {
    position: relative;
    color: rgb(51 51 51);
    text-decoration: none;
    text-transform: uppercase;

    /*padding-right: 10px;*/
    /*padding-left: 10px;*/

    font-size: 13px;
    font-weight: 600;
    line-height: 1.2;
    font-family: "Lato", Arial, Helvetica, sans-serif;
}

.header-navbar_item::after {
    content : "";
    position : absolute;
    width : 100%;
    height : 2px;
    background-color: rgba(245, 102, 34, 1);
    bottom: -2px;
    left: 0;
    transform : scaleX(0);
    transform-origin: bottom left;
    transition : transform 0.25s linear;
}

.header-navbar_item:hover {
    color: rgb(51 51 51);
}

.header-navbar_item:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
}

.site-logo {
    max-height: 90px;
}

.site-logo_mobile {
    max-height: 3rem;
}
/*!* Dropdown Button *!*/
/*.dropbtn {*/
/*    background-color: #04AA6D;*/
/*    color: white;*/
/*    padding: 16px;*/
/*    font-size: 16px;*/
/*    border: none;*/
/*}*/

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
    text-align: start;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: block;
    margin-top: 10px;
    visibility: hidden;
    position: absolute;
    width: 200px;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    transition: visibility 0.2s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}

/* Links inside the dropdown */
.dropdown-content a {
    font-weight: 300;
    color: black;
    padding: 12px 12px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {color: rgba(245, 102, 34, 1);}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {visibility: visible;}

/*!* Change the background color of the dropdown button when the dropdown content is shown *!*/
/*.dropdown:hover .dropbtn {background-color: #3e8e41;}*/
