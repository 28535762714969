.thumb.selected, .thumb:hover {
    border: none !important;
    opacity: 0.3;
    transition: opacity 0.3s;
}

.thumb, .thumb:focus {
    border: none !important;
}

.carousel-status {
    visibility: hidden;
}