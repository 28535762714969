.address-form_wrapper {
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1000px) {
    .address-form_wrapper {
        flex-direction: column;
    }
}

.address-form {
    width: 50%;
    margin-right: 10px;
}

@media screen and (max-width: 1000px) {
    .address-form {
        width: 100%;
        margin-right: 0;
    }
}

.address-form_first-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

@media screen and (max-width: 1000px) {
    .address-form_first-row {
        flex-direction: column;
        gap: 0;
    }
}

.invalid-feedback {
    color: black !important;
}

.checkout-go-back {
    margin: 5px;
}