.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f7f7f7;
    width: 50%;
}

.tableWrapper {
    min-width: 95%;
    background-color: white;
}

.table {
    margin: 1.25rem;
}

@media screen and (max-width: 1000px) {
    .wrapper {
        width: 100%;
    }
}

.order-payment-fee {

}

.confirmButton {
    width: 90%;
    border-radius: 30px !important;
    margin-bottom: 10px;
}

.noBorder {
    border: none !important;
}
